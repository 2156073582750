html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: fugaz-one;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

:root {
  --dark: #0b2d73;
  --primary: #ffae01;
  --success: #2aae4f;
  --danger: #ff5252;
  --gray: #6f6f6f;
  --sky: #0079fd;
  --orange: #f2994a;
  --black-20: rgba(0, 0, 0, 0.2);
  --black-60: rgba(0, 0, 0, 0.5);
  --black-80: rgba(0, 0, 0, 0.8);
  --white: #fff;
  --white-10: rgba(255, 255, 255, 0.1);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-60: rgba(255, 255, 255, 0.6);

  --grid-row-height: 24px;
  --grid-team-width: 200px;
  --grid-connector-width: 26px;
}

/* Declare fonts */
@font-face {
  font-family: 'fugaz-one';
  src: url('./assets/fonts/FugazOne-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

p {
  margin: 0;
}

input {
  outline: none;
  caret-color: var(--primary);
  font-family: 'fugaz-one';
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

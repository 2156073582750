.Toastify__toast {
  min-height: 44px;
  padding: 8px 12px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: var(--success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: var(--primary);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: var(--danger);
}

.Toastify__toast-body > div:last-child {
  font-family: inter;
  text-transform: capitalize !important;
}
